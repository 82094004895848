import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Div } from "../components/Primitives"
interface Props {
  alt: string
  path: any
  height: any
  width: any
}

const Image = (props: Props) => {
  return props.alt == "Slpertech" ? (
    <Div>
      <Images src={props.path} width={props.width} height={props.height} />
    </Div>
  ) : (
    <Div>
      <img src={props.path} width={props.width} height={props.height} />
    </Div>
  )
}

export default Image

const Images = styled.img`
  margin-top: -20px;
`

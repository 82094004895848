import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Image from "../components/image"
import ess from "../../static/logos/ess.png"
import crestex from "../../static/logos/crestex.png"
import gtech from "../../static/logos/gtech.png"
import lntech from "../../static/logos/lntech.png"
import slpertech from "../../static/logos/tech.png"
import officeGirl from "../../static/images/office-phone.png"
import girls from "../../static/images/pexels-photo.webp"
import girlspic from "../../static/images/pexels-photo-girls.webp"
import sci from "../../static/logos/sci.png"
import mentor from "../../static/logos/mentor.png"
import xynotech from "../../static/logos/xynotech_logo.png"
import quotes from "../../static/images/quotation-marks.png"
import "../components/layout.css"
import {
  Container,
  ContentContainer,
  Resources,
  deviceMax,
  SeeMore,
  deviceMin
} from "../components/Primitives"
import ResourceHero from "../components/Resources/resourceHero"
import meta from "../../content/data/meta.json"
import styled from "styled-components"
import NewsLetter from "../components/newsletter"
import MyCard from "../components/Resources/myCard"
import GitHub from "../../static/Icons/GitHub"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "../../static/styles/styles.scss"
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Swiper, Navigation, Pagination, Autoplay } from "swiper"

const swiperParams = {
  Swiper,
  modules: [Navigation, Pagination, Autoplay],
  slidesPerView: 3,
  spaceBetween: 20,
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: ".swiper-pagination",
    type: 'fraction',
    clickable: true
  },
  breakpoints: {
    1024: {
      slidesPerView: 3,
      spaceBetween: 20
    },
    760: {
      slidesPerView: 2,
      spaceBetween: 0,
      slidesPerColumn: 1
    },
    375: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 0
    }
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  loop: true
}
const IndexPage = ({ data, animationContainerReference }: any) => (
  <Layout>
    <SEO pathName='/' title="Home" />
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={girls} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={officeGirl} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={girlspic} className="d-block w-100" alt="..." />
        </div>
      </div>
      <a
        className="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </a>
    </div>
    <div id="about" />
    <div className="container" style={{ maxWidth: "1200px" }}>
      <CenterDiv>
        <H1_Attn>About Us</H1_Attn>
      </CenterDiv>
        <div className="row">
          <div className="col-xs-12 col-sm-8 col-sm-push-4 col-md-6 col-md-push-6">
            <div className="info-text right">
              <p>
                We are committed to meeting the needs of our clients in a timely
                and cost effective manner. Our goal is to offer our clients the
                option that best suits their needs, be it a short or long term
                assignment, or on a project or ad hoc basis and our research
                effectiveness, innovation, flexibility and professionalism make
                us a powerful and effective resource for your organization
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-sm-pull-8 col-md-6 col-md-pull-6">
            <div className="col-xs-12 col-sm-12">
              <figure className="snip1374">
                <img
                  src="https://cdn.shopify.com/s/files/1/2204/2817/files/featurette-5-1000x1000.jpg?98122347912348223"
                  alt="sample57"
                />
              </figure>
            </div>
          </div>
        </div>
      <CenterDiv style={{ marginBottom: "5%" }}>
        <H1_Attn>Our Edge</H1_Attn>
      </CenterDiv>
      <div
        className="row"
        style={{ justifyContent: "center", marginBottom: "30px" }}
      >
        <figure className="snip1170 blue">
          <img
            src="https://www.sonic.com/sites/default/files/careers_people_icon_2-v2.png"
            alt="sample67"
          />
          <span>Fast Hiring</span>
        </figure>
        <figure className="snip1170 green">
          <img
            src="https://pngimage.net/wp-content/uploads/2018/05/cost-effective-icon-png-3.png"
            alt="sample64"
          />
          <span>Cost Effective</span>
        </figure>
        <figure className="snip1170 red">
          <img
            src="https://image.flaticon.com/icons/png/512/1134/1134194.png"
            alt="sample80"
          />
          <span>Expertise</span>
        </figure>
      </div>
    </div>

    <div
      style={{ backgroundColor: "#F7CDE0", width: "100%", padding: "1% 0%" }}
    >
      <CenterDiv style={{ marginBottom: "5%" }}>
        <H1_Attn>Some of the companies we worked for</H1_Attn>
      </CenterDiv>
      <Resources
        data-sal="slide-down"
        data-sal-delay="300"
        data-sal-duration="500"
        data-sal-easing="ease"
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "80%"
        }}
      >
        <Image
          path={ess}
          alt="Erly Stage Studios"
          width="180px"
          height="40px"
        />
        <Image path={crestex} alt="Crestex" width="170px" height="45px" />
        <Image path={gtech} alt="Gtech" width="170px" height="50px" />
        <Image path={lntech} alt="lntech" width="170px" height="50px" />
      </Resources>
      <Resources
        data-sal="slide-down"
        data-sal-delay="200"
        data-sal-duration="600"
        data-sal-easing="ease"
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "80%",
          paddingBottom: "5%"
        }}
      >
        <Image path={sci} alt="Crestex" width="170px" height="45px" />
        <Image path={mentor} alt="Gtech" width="170px" height="50px" />
        <Image path={xynotech} alt="xynotech" width="200px" height="65px" />
        <Image path={slpertech} alt="Slpertech" width="170px" height="100px" />
      </Resources>
    </div>
    <div
      className="container"
      style={{ marginTop: "20px", marginBottom: "70px" }}
    >
      <CenterDiv style={{ marginBottom: "5%" }}>
        <H1_Attn>What Our Clients Say About Us</H1_Attn>
      </CenterDiv>
      <ReactIdSwiperCustom {...swiperParams}>
        <div>
          <div className="review-box">
            <div className="media">
              {/*<img*/}
              {/*  className="mr-3"*/}
              {/*  src="https://picsum.photos/50/50"*/}
              {/*  alt="Generic placeholder image"*/}
              {/*/>*/}
              <div className="media-body">
                <h5>Daniyal Noor</h5>
                <p className="user-post">Gtech Ventures Pvt Ltd</p>
              </div>
            </div>
            <p
              className="read"
              style={{ lineHeight: "25px", fontSize: "16px" }}
            >
              “Career Ladder has been a great partner in attracting and hiring
              the best talent in a limited time. We wish to stay partners with
              Career Ladder in adding value to our Human Capital.”
            </p>
            <img className="quote" src={quotes} alt="" />
          </div>
        </div>
        <div>
          <div className="review-box">
            <div className="media">
              {/*<img*/}
              {/*  className="mr-3"*/}
              {/*  src="https://picsum.photos/50/50"*/}
              {/*  alt="Generic placeholder image"*/}
              {/*/>*/}
              <div className="media-body">
                <h5>Mujtaba Ahmed</h5>
                <p className="user-post">Scientise7 Pvt Ltd</p>
              </div>
            </div>
            <p
              className="read"
              style={{ lineHeight: "25px", fontSize: "16px" }}
            >
              “Tazeen has been absolutely amazing throughout the whole
              recruitment process! I have never met such a professional and
              dedicated recruitment person. We wish her all the best and may we
              stay together as long term partners.“
            </p>
            <img className="quote" src={quotes} alt="" />
          </div>
        </div>
        <div>
          <div className="review-box">
            <div className="media">
              {/*<img*/}
              {/*  className="mr-3"*/}
              {/*  src="https://picsum.photos/50/50"*/}
              {/*  alt="Generic placeholder image"*/}
              {/*/>*/}
              <div className="media-body">
                <h5>Usman Aziz</h5>
                <p className="user-post">Business Analyst</p>
              </div>
            </div>
            <p
              className="read"
              style={{ lineHeight: "25px", fontSize: "16px" }}
            >
              “Career Ladder people have been amazing. They prepared me
              extremely well for interviews, they were very patient with me &
              kept in constant contact.They restored my faith in agencies.“
            </p>
            <img className="quote" src={quotes} alt="" />
          </div>
        </div>
        <div>
          <div className="review-box">
            <div className="media">
              {/*<img*/}
              {/*  className="mr-3"*/}
              {/*  src="https://picsum.photos/50/50"*/}
              {/*  alt="Generic placeholder image"*/}
              {/*/>*/}
              <div className="media-body">
                <h5>Quratulain S.Sikandar</h5>
                <p className="user-post">MENTOR CLUB</p>
              </div>
            </div>
            <p
              className="read"
              style={{ lineHeight: "22px", fontSize: "16px" }}
            >
              “We have worked with Career Ladder for a number of years. Tazeen
              has a high level of integrity as a Recruiter and always takes time
              to fully brief herself on our vacancies, putting forward only
              those candidates who closely match our requirements. We would have
              no hesitation in recommending Career Ladder for their excellent,
              cost-effective service.“
            </p>
            <img className="quote" src={quotes} alt="" />
          </div>
        </div>
        <div>
          <div className="review-box">
            <div className="media">
              {/*<img*/}
              {/*  className="mr-3"*/}
              {/*  src="https://picsum.photos/50/50"*/}
              {/*  alt="Generic placeholder image"*/}
              {/*/>*/}
              <div className="media-body">
                <h5>Muneeb Ahmed</h5>
                <p className="user-post">Cybarea Pvt Ltd</p>
              </div>
            </div>
            <p
              className="read"
              style={{ lineHeight: "25px", fontSize: "16px" }}
            >
              “Career Ladder is one of the reliable consultants that makes my
              life easy by making company forms and basic policies and
              procedures for my small software house. Highly recommended to
              everyone who has a small setup and needs HR help.“
            </p>
            <img className="quote" src={quotes} alt="" />
          </div>
        </div>
      </ReactIdSwiperCustom>
    </div>
  </Layout>
)
export default IndexPage
// export default IndexPage

// export const IndexQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(
//       filter: { fileAbsolutePath: { regex: "/content/resources/job-seekers/" } }
//       limit: 3
//     ) {
//       edges {
//         node {
//           id
//           timeToRead
//           html
//           excerpt(pruneLength: 400)
//           frontmatter {
//             author
//             slug
//             title
//             tags
//             date
//             source
//             resourceType
//             position
//           }
//           internal {
//             content
//           }
//         }
//       }
//     }
//     newsImage: file(relativePath: { eq: "ess.png" }) {
//       childImageSharp {
//         fluid(quality: 100) {
//           srcSetWebp
//         }
//       }
//     }
//   }
// `

const HeroWrap = styled.div`
  background-image: url("images/office-phone.png");
  padding: 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  min-height: 768px;
`
const Overlay = styled.div`
  height: 100%;
  width: 100%;
  min-height: 768px;
  background-color: rgba(2, 38, 64, 0.4);
`
const CenterDiv = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const HeroContainer = styled(Container)`
  padding: 200px 20px;
  color: white;
`
const H1_Attn = styled.h1`
  text-align: center;
  color: #85083e;
  margin: 50px 0;
`
const H1 = styled.h1`
  text-align: center;
  margin: 50px 0;
`
const P = styled.p`
  text-align: center;
`
const HeroImage = styled.div`
  width: 100%;
  height: 90vh;
  object-fit: cover;
  background-color: blue;
  @media ${deviceMin.mobileS} {
    background-color: green;
    height: 40vh;
  }
`

const GitHubLink = styled(OutboundLink)`
  margin: auto;
  display: block;
  text-align: center;
  width: max-content;
`
